/* @flow */

import * as React from 'react';
import classnames from 'classnames';
import {colors, type ColorName} from '../colors';
import './card.css';

export type CardProps = {
    children: React.Node,
    hasBorder: boolean,
    /** By default, we will show a shadow if `hasBorder` is false, but not if it is true. */
    hasShadow?: boolean,
    /** If hasBorder is `true`, optionally change the color. */
    borderColor?: string,
    /** Optionally change the border radius. */
    borderRadius?: number,
    /** If hasDottedBorder is `true`, display a dotted grey border. */
    hasDottedBorder?: boolean,
    /** If true, the card will size horizontally according to the content, not the parent's width */
    fitContent: boolean,
    /** If true, the card will size vertically according to the content, not the parent's height */
    fitHeight: boolean,
    /** We can optionally add a colored line along the left side */
    leftLineColor?: string,
    onClick?: (e: SyntheticEvent<*>) => void,
    getCardRef?: any,
    backgroundColor?: ColorName,
    overflowVisible?: boolean,
};

Card.defaultProps = {
    borderColor: colors.offWhiteDk,
    fitContent: false,
    fitHeight: false,
};

Card.displayName = 'Card';

/**
 * This is a simple UI component that gives us a standard way of creating
 * a "card" with rounded corners and an optional shadow.
 *
 * It is up to the component using the Card shell to provide all of the
 * contents via \`children\`.
 */
export function Card({
    hasBorder,
    hasShadow,
    children,
    borderColor,
    borderRadius,
    hasDottedBorder,
    fitContent,
    fitHeight,
    leftLineColor,
    onClick,
    getCardRef,
    backgroundColor,
    overflowVisible,
}: CardProps) {
    const containerStyleName = classnames({
        'card--bordered': hasBorder,
        'card--shadowed': typeof hasShadow !== 'undefined' ? hasShadow : !hasBorder,
        'fit-content': fitContent,
        'fit-height': fitHeight,
        'card--button': onClick,
        'overflow-visible': overflowVisible,
    });

    const getStyles = (): {} => {
        const styles = {};

        if (hasDottedBorder) {
            styles.borderStyle = 'dashed';
            styles.borderColor = colors.grey;
        } else if (leftLineColor) {
            styles.borderColor = borderColor;
            styles.borderLeft = `3px solid ${leftLineColor}`;
        } else {
            styles.borderColor = borderColor;
            // have to reassign borderLeft if it was overwritten by leftLineColor
            styles.borderLeft = borderColor && `1px solid ${borderColor}`;
        }

        if (backgroundColor) {
            styles.backgroundColor = colors[backgroundColor];
        }

        if (typeof borderRadius === 'number') {
            styles.borderRadius = `${borderRadius}px`;
        }

        return styles;
    };

    return (
        <div ref={getCardRef} styleName={containerStyleName} style={getStyles()} onClick={onClick}>
            {children}
        </div>
    );
}
